@import "../../../baseTheme/settings";

.rewards-banner {
    position: relative;
    margin:0 auto 24px;
    

    @include media-breakpoint-up(md) {
        margin:0 auto 32px;
    }

    .banner-image {
        position: relative;

        .banner-overlay {
            overflow: hidden;
            border-radius: 12px;

            img {
                display: block;
                width: 100%;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
            }

        }

        @include media-breakpoint-down(md) {
            padding-top: 42px;
        }
    }

    .banner-caption {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        align-content: flex-end;
        position: absolute;
        overflow: hidden;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        align-self: center;
        justify-content: center;

        @include media-breakpoint-up(md) {
            align-content: center;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .caption-wrapper {
            display: flex;
            color: #1E1E1E;
            flex-direction: column;
            align-self: center;
            justify-content: center;
            flex-grow: 1;
            text-align: center;

            @include media-breakpoint-up(md) {
                text-align: left;
                max-width: 600px;
            }

            .banner-text {
                text-align: center;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    text-align: center;
                    margin-bottom: 12px;
                }

                p {
                    text-align: center;
                    margin-bottom: 24px;
                    width: 80%;

                    @include media-breakpoint-down(md) {
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: -0.28px; 
                        margin-bottom: 8px;
                    }
                }

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0 0 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        width: 110px;
                        text-align: center;
                        color: get-brand-color(sageGreen);
                        font-size: 12px;
                        letter-spacing: -0.24px;
                        margin-right: 40px;
                        @include primaryFont(700);

                        &:last-child {
                            margin-right: 0;
                        }

                        img {
                            margin-bottom: 8px;
                        }

                        @include media-breakpoint-down(md) {
                            font-size: 10px;
                            letter-spacing: -0.216px;
                            width: 90px;
                            margin-right: 30px;
                        }
                    }
                }

                h3 {
                    @include media-breakpoint-down(md) {
                        font-size: 30px;
                        line-height: 32px;
                        letter-spacing: -0.28px; 
                        margin-bottom: 8px;
                    }
                }
            }

            .banner-cta {
                display: flex;
                align-items: center;
                justify-content: center;

                .btn {
                    height: auto;
                    padding: 0 25px;
                    text-transform: uppercase;
                    line-height: 40px;
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 16px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &.btn-white {
                        @include media-breakpoint-down(md) {
                            background-color: get-brand-color(mist);
                        }
                    }

                    
                }

                
            }

        }

        @include media-breakpoint-down(md) {
           position: static;
        }

    }

    &.banner-small-banner {
        max-width: 1440px;
        .banner-image {
            &:before {
                height: 400px;
                @include media-breakpoint-up(xl) {
                    height: 315px;
                }
            }
        }

        .banner-caption {
            padding-top: 30px;
            align-content: flex-start;
            @include media-breakpoint-up(md) {
                align-content: center;
                padding-top: 0;
            }
        }

        .caption-wrapper {
            @include media-breakpoint-up(md) {
                text-align: left;
                max-width: 400px;
            }
            h2 {
                margin: 0 0 16px;
                font-weight: 400;
                letter-spacing: -0.113rem;
                font-size: 24px;
                line-height: 28px;
                @include media-breakpoint-up(md) {
                    font-size: 36px;
                    line-height: 38px;
                    letter-spacing: -0.27rem; 
                }
            }
        }
    }

    &.banner-overTop {
        .banner-caption {
            @include media-breakpoint-down(md) {
                position: absolute;
                top: auto;
            }

            .caption-wrapper {
                .banner-text {
                    h3 {
                        @include media-breakpoint-down(md) {
                            font-size: 24px;
                            letter-spacing: -0.96px; 
                            line-height: 28px;
                        }
                    }
                }
                .banner-cta {
                    .btn {
                        @include media-breakpoint-down(md) {
                            width: 70%;
                            margin-right: 0;
                            margin-bottom: 16px;
                            
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .btn-secondary {
                        .icon-arrow {
                            display: none;
                        }
                    }

                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                    }
                }
            }
        }
    }
}